.chatRow {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid var(--primary-bg);
  /* overflow: hidden; */
}

.chatRowNotification {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--primary-bg);
  background-color: var(--primary-bg);
  position: relative;
}

.chatRowNotification:hover {
  background-color: var(--primary-bg);
}

.chatNotification {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-purple);
}

.chatRowSelected {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--primary-bg);
  background-color: var(--primary-bg);
  position: relative;
}

.chatRowSelected:hover {
  background-color: var(--primary-bg);
}

.chatRow:hover {
  cursor: pointer;
  background-color: var(--primary-bg-light);
}

.pillOfferSent {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--primary-red-100);
  color: var(--primary-red-400);
  font-size: 0.7em;
  padding: 0.3em;
  border-end-start-radius: 4px;
  font-size: 10px;
}

.listingImage {
  height: 50px;
  width: 50px;

  object-fit: cover;
  border-radius: 4px;
  transition: 0.5s ease;
  position: relative;
}

.listingImage:hover {
  transition: 0.5s ease;
  opacity: 0.7;
}
.imagesSection {
  margin-right: 1rem;
  position: relative;
}

.chatInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.offerBadge {
  background-color: var(--primary-purple);
  color: white;
  font-weight: 600;
  font-size: 10px;
  padding: 1px;

  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  text-align: center;
  max-width: 33px;
  margin-bottom: 4px;
}

.orderBadge {
  color: #222222;
  font-weight: 600;
  font-size: 13px;
  padding: 1px;
  margin-bottom: 4px;

  border-radius: 3px;
}

.infoText {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  white-space: nowrap;
  margin-bottom: 4px;
  font-weight: 400;

  font-size: 13px;
  color: #222222;
}

.infoTextNotification {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  white-space: nowrap;
  margin-bottom: 4px;

  font-size: 13px;
  color: var(--primary-purple);
  font-weight: 600;
}

.originalPrice {
  display: inline-block;
  color: #868686;
  text-decoration: line-through;
}

.expire {
  margin-right: 0.2rem;
}

.expired {
  color: #222222;
}

.countdownDiv {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #a7171a;
}

.orderHeader {
  font-weight: 600;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sent {
  display: flex;
  text-align: end;
  align-items: center;
  font-size: 13px;
  color: #5e5e5e;
  white-space: nowrap;
  margin-bottom: 4px;
}

.plusItems {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #5e5e5e;
  color: white;
  font-weight: 600;
  max-height: 2rem;
  max-width: 3em;
  font-size: 10px;
  padding: 5px;
  border-bottom-left-radius: 7px;
  position: absolute;
  right: 0;
  top: 0;
}
