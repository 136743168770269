.goBack {
  margin-top: 1em;
  margin-left: 2em;
  font-size: 1.5em;
}

.goBack:hover {
  cursor: pointer;
}

.loadingSpinner {
  margin: auto;
  margin-top: 5em;
  width: 10%;
}

.closedChat {
  width: 100%;
  text-align: center;
  color: rgba(128, 128, 128, 0.715);
  border-top: 1px solid rgba(128, 128, 128, 0.194);
  padding: 2em;
}

.centerLoadingSpinner {
  margin: auto;
  margin-bottom: 0.5em;
}

.chatHeader {
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 0.3em;
  font-family: "Editorial";

  font-size: 3em;
}

.expiration {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #a7171a;
  margin-top: 4px;
}

.expiration1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: #a7171a;
  margin-top: 4px;
}

.expire {
  margin-right: 0.2rem;
}

.expired {
  color: #222222;
}

.name {
  padding-left: 0.5rem;
  color: #222222;
  text-decoration: none !important;
  font-size: 16px;
}

.archiveButton {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: rgb(106, 106, 106);
  font-size: small;
  font-weight: 400;
  cursor: pointer;
}

.archiveIcon {
  margin-right: 0.5em;
}

.receiverPicture {
  object-fit: cover;
  height: 35px;
  width: 35px;
  cursor: pointer;
  border-radius: 50% !important;

  background-color: white;
}

.messagesContainer {
  position: relative;
  flex-direction: row-reverse;
  overflow-y: scroll;
  padding-bottom: 7rem;
  background-color: white;
  height: calc(100% - 60px - 60px - 5rem);

  padding-left: 1rem;
  padding-right: 1rem;
}

.messagesContainerOrders {
  position: relative;
  flex-direction: row-reverse;
  overflow-y: scroll;
  background-color: white;
  padding-bottom: 2rem;

  height: calc(100% - 60px - 5rem);

  padding-left: 1rem;
  padding-right: 1rem;
}

.newMyMessage {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1em;
}

.newNotMyMessage {
  position: relative;

  display: flex;
  flex-direction: row;
  padding-top: 1em;
}

.myMessage {
  padding: 0.4em;
  border-radius: 18px;
  border-bottom-right-radius: 0;
  width: auto;
  /* background: var(--primary-purple)5e; */
  background-color: var(--primary-purple);
  color: white;
  display: flex;
  font-size: 14px;
  max-width: 70%;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
}

.offerBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notMyMessage {
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  font-size: 14px;

  padding: 0.4em;
  border-radius: 18px;
  border-bottom-left-radius: 0;
  width: auto;
  background: var(--primary-bg);
  color: black;
  width: fit-content;
  max-width: 70%;
}

/* IMAGE MESSAGES */

.sentImage {
  width: 20em;
  height: 20em;
  object-fit: contain;
}

.textImage {
  -webkit-margin-before: auto;
  -webkit-margin-after: auto;
  margin-block: auto;
}

.newMyMessageImage {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  margin-bottom: 1em;
}

.newNotMyMessageImage {
  margin-top: 1em;
  margin-bottom: 1em;
}

.myMessageImage {
  padding: 0.2em;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  width: auto;
  /* background: var(--primary-purple)5e; */
  display: flex;
  justify-content: flex-end;
  box-shadow: var(--box-shadow-1);
}

.notMyMessageImage {
  padding: 0.2em;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  width: auto;
  width: fit-content;
  box-shadow: var(--box-shadow-1);
}

.text {
  margin-top: auto;
  margin-bottom: auto;
  /* overflow-x: scroll; */
  padding-left: 0.5rem;
  font-size: 15px;
  font-weight: 400 !important;
  line-height: 22px;
  padding-right: 0.5rem;
}

.infoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputDiv {
  background-color: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--primary-bg);

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
}

.orderActionDiv {
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--primary-bg);
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  height: 5rem;
}

.declineOrderButton {
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  color: black;
  text-align: center;
  width: 45%;
  border-radius: 5px;
  cursor: pointer;
  height: 50%;
}

.approveOrderButton {
  background-color: var(--primary-purple);
  color: white;
  text-align: center;
  width: 45%;
  cursor: pointer;
  border-radius: 5px;

  height: 50%;
}

.approveOrderButton:hover {
  background-color: #458240;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.declineOrderButton:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: var(--primary-bg-dark);
}

.inputWrapper {
  height: 100%;
  width: 80%;
  position: relative;
}

.input {
  width: 100%;
  height: 100%;
  border: 0px solid white;
  border-radius: 5px;
  padding: 0.5em 0em 0.5rem 3rem;
  /* overflow: auto;
	max-height: 5em; */
  outline: none;
  line-height: 22px;
  overflow-y: auto;
  overflow-wrap: break-word;
  resize: vertical;
  max-height: 200px;
  min-height: 60px;
}

.sendPic {
  position: absolute;
  color: #aaaaaa;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.deletedText {
  font-size: 12px;
  text-align: center;
  color: white;
  padding-left: 0.5rem;
  margin-top: 4px;
  margin-bottom: 0px;

  padding-right: 0.5rem;
}

.businessIcon {
  width: 2em;
  height: 2em;
  color: var(--primary-purple);
}

.deletedText2 {
  font-size: 12px;
  text-align: center;
  color: black;
  padding-left: 0.5rem;
  margin-top: 4px;
  margin-bottom: 0px;

  padding-right: 0.5rem;
}

.sendPic:hover {
  color: #dadada;
}

.sendButton {
  background-color: var(--primary-purple);
  color: white;
  border-radius: 4px;
  width: auto;
  height: 2.5em;
  padding-left: 0.7em;
  padding-right: 0.7rem;
  margin-left: 0.2em;
  margin-right: 10px;
}

.sendButton:hover {
  opacity: 0.2;
}

.sendButtonDeactive {
  background-color: var(--primary-purple);

  opacity: 0.2;
  color: white;
  border-radius: 4px;
  width: auto;
  height: 2.5em;
  padding-left: 0.7em;
  padding-right: 0.7rem;
  margin-left: 0.2em;
  margin-right: 10px;
}
.sendButtonDeactive:hover {
  cursor: not-allowed !important;
}
/* input {
  color: none;
  background-color: none;
  border-style: none;
  border-width: 1px;
} */

.paddingChat {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #222222;
  height: 60px;
  justify-content: space-between;
  align-items: center;

  border-bottom: 0.5px solid var(--primary-bg);
}

.column {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 70%;
}

.col {
  /* display: flex;
	flex-direction: column;
	justify-content: center; */
  padding-left: 1rem;
  width: 100%;
}

.listingPrice {
  color: #868686;
  margin-bottom: 0rem;
}

.paddingChat1 {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #222222;
  height: 60px;
  cursor: pointer;
  align-items: center;

  border-bottom: 0.5px solid var(--primary-bg);
}

.box {
  margin-left: 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.offerInfoBox {
  width: 70%;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: var(--primary-bg) 44;
  border-radius: 7px;
  color: rgba(0, 0, 0, 0.647);
  text-align: center;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.314) 0 3px 9px 0;
  /* background-image: linear-gradient(var(--primary-purple)23, white); */
  /* color: gray; */

  font-size: 8 !important;
}

.offerInfo {
  font-weight: normal;
  font-size: 10;
  font-weight: 600;
}

.dateDiv {
  margin-top: auto;
  font-size: 13px;
  color: #5e5e5e;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
  color: #222222;
  font-size: 14px;
}

.originalPrice {
  color: #99a3a6;
  text-decoration: line-through;
  margin-left: 0.2rem;
}

.textHeadingChat {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  white-space: nowrap;
  font-weight: 500;

  display: inline-block;
  font-size: 14px;
  color: #222222;
  vertical-align: middle;
}

.orderTypePill {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--primary-purple);
  border-radius: 3px;
  padding-inline: 4px;
  text-align: center;
  font-size: 12px;
  height: fit-content;
}

.soldListing {
  font-size: 1em;

  color: var(--primary-red-400);
  font-weight: 500;
  margin-left: 1rem;

  z-index: 1;
}

/* button {
	color: none;
	background-color: white;
	border-color: white;
	border-style: none;
	border-width: 1px;
	vertical-align: middle;
} */

.orderDetails {
  font-size: 12px;
}

.listingImage {
  max-height: 50px;
  max-width: 50px;
  height: 50px;
  width: 50px;
  object-fit: cover;
  transition: 0.5s ease;
}

.listingImage:hover {
  transition: 0.5s ease;
  opacity: 0.7;
}

.iconBack {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* LISTING */

.listingBox {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.title {
  font-weight: 600;
  display: inline-block;
  text-align: center;
}

.price {
  font-weight: 600;
  color: var(--text-main);
  display: inline-block;
  text-align: center;
  font-size: 2em;
  margin-left: 1em;
}

.smallButton {
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  /* overflow-x: scroll; */
  padding-left: 0.5rem;
  font-size: 12px;
  line-height: 22px;
  text-decoration: underline;
  padding-right: 0.5rem;
}

.smallButton:hover {
  cursor: pointer;
}

.update {
  width: 85%;
  margin: auto;
  background-color: var(--primary-bg);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1em;
  border-radius: 7px;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.customAlert {
  max-width: 15em;
  background-color: white;
  border-radius: 10px;
  padding: 0.5em;
  color: var(--primary-purple);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  margin-block: 1em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
}

.marginButtons {
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  margin-block: 1em;
}

.p {
  margin-bottom: 0rem;
}

.chatStart {
  width: 85%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0rem;
  text-align: center;
  font-style: italic;
  color: gray;
  font-size: 14px;
}

/* Fun Buttons */
.holder {
  background: var(--primary-purple);
  padding: 10px;
  max-width: 250px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.button {
  background: white;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  height: 2.5rem;
  width: 100%;
  overflow: hidden;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.btnTwo {
  margin-bottom: 0rem;

  position: relative;
  width: 200px;
  height: 100px;
  margin-top: -100px;
  padding-top: 2px;
  background: var(--primary-purple);
  left: -250px;
  transition: 0.3s;
}
.btnText {
  margin-bottom: 0rem;
  padding: 0.5rem;
  color: var(--primary-purple);
  transition: 0.3s;
  height: 100%;
}
.btnText2 {
  margin-bottom: 0rem;

  color: #fff;
}
.button:hover .btnTwo {
  /*When hovering over .button change .btnTwo*/
  left: -130px;
}
.button:hover .btnText {
  /*When hovering over .button change .btnText*/
  margin-left: 65px;
}
.button:active {
  /*Clicked and held*/
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}

.fixedSize {
  position: relative;
}

.scheduleButtonClosed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70%;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  text-align: center;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 7px;
  color: white;
  background-color: var(--primary-purple);
  height: 40px;

  margin-bottom: -0.5em;
  transition: 0.2s linear;
  z-index: 200;
}

.scheduleButtonClosed:hover {
  cursor: pointer;
  transition-property: all;
  transition: ease-in;
  color: white;
  background-color: var(--primary-purple);

  font-weight: 600;
}

.scheduleButtonOpen {
  position: absolute;
  top: 10;
  bottom: 0;
  left: 0;
  right: 0;

  /* align-self: flex-end;
	position: absolute;
	bottom: 0px;
	width: 70%;
	margin-bottom: 2em; */
  justify-content: center;
  width: 70%;
  margin: auto;
  text-align: center;
  /* margin-top: 0.5em; */
  border-radius: 7px;
  color: #ffbf00;
  border: 2px solid #5e5e5e;
  background-color: white;
  min-height: 10em;
  font-weight: 600;
  margin-bottom: 0.2em;
  z-index: 2;
  transition: 0.2s linear;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Time & Date Picker */
.datePicker {
  width: 100%;
}
.timeOrDate {
  display: inline-block;
  -webkit-margin-before: 0.1em;
  -webkit-margin-after: 0.1em;
  margin-block: 0.2em;
}

.picker {
  border: solid 1px #99a3a6;
  text-align: center;
  width: auto;
  border-radius: 10px;
}

.boxShadow {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  -webkit-padding-before: 1em;
  -webkit-padding-after: 1em;
  padding-block: 1em;
}

.bgColor {
  background-color: #5e5e5e;
  font-size: 14px;
  color: white;
  -webkit-padding-before: 1em;
  -webkit-padding-after: 1em;
  padding-block: 1em;
  -webkit-padding-start: 0.5em;
  -webkit-padding-end: 0.5em;
  padding-inline: 1em;
  margin-bottom: 0rem;
}

.pText {
  margin-bottom: 0rem;
}

.slot {
  padding: 0.5em;
  border: 1px solid var(--primary-purple);
  color: white;
  background-color: var(--primary-purple);
  width: 90%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-size: 12px;
  border-radius: 18px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.marginBlock {
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  margin-block: 1em;
}

.timePickerText {
  color: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0rem;
}

.pointer {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: var(--primary-bg);
  border: none;
}

.pointerError {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: var(--primary-bg);
  border: 1px dashed var(--primary-red-400);
}

.error {
  font-size: 12px;
  color: #e30000;
}

.removeSlot {
  color: #e30000;
  height: 1.5em;
  width: 1.5em;
  /* display: flex;
	justify-content: flex-end;
	margin-right: 1.5em; */
  margin-top: 0.5em;
  margin-right: 0.5em;
  position: absolute;
  top: 0;
  right: 0;
}

.removeSlot:hover {
  cursor: pointer;
}
.boxShadowSubmit {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-padding-before: 1em;
  -webkit-padding-after: 1em;
  padding-block: 1em;
}

.sendTimesButton {
  background-color: var(--primary-purple);
  color: white;
  padding: 0.5em;
  width: 90%;

  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  max-width: 20em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sendTimesButton:hover {
  cursor: pointer;
  transform: translateY(-1px);
  background-color: var(--primary-purple);
}

.sendTimesButtonDisabled {
  border: 2px solid var(--primary-purple);
  color: var(--primary-purple);
  padding: 0.5em;
  border-radius: 7px;
  max-width: 20em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.timeSlotNoAction {
  padding: 0.3em;
  margin: auto;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  border: 1.5px solid var(--primary-purple);
  border-radius: 18px;
  max-width: 20em;
  font-size: 12px;
}

.timeSlotYesAction {
  padding: 0.3em;
  margin: auto;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  background-color: var(--primary-purple);
  border: 1.5px solid var(--primary-purple);

  color: white;
  font-weight: 600;
  border-radius: 18px;
  max-width: 20em;

  font-size: 12px;
}

.timeSlotYesAction:hover {
  cursor: pointer;
  max-width: 20em;
  box-shadow: rgba(100, 100, 111, 0.404) 0px 9px 31px 0px;
  background-color: #127012;
  transform: translateY(-1px);
}

.button1 {
  width: 100%;
  color: var(--primary-purple);
}

.breakDownBox {
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  background-color: var(--primary-bg);
}

.separator {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  width: calc(100% - 1rem);
  background-color: #99a3a6;
  height: 1px;
}

.breakDownText {
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #99a3a6;
  font-size: 13px;
}

.infoText {
  color: #99a3a6;
  font-size: 13px;
  text-align: center;
}

.newParagraph {
  margin-top: 1em;
  font-style: italic;
}

.code {
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--primary-purple);
  font-family: "SocialGothicMedium";
}

.codeInput {
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.404) 0px 7px 29px 0px;
  height: 2em;
  text-align: center;
  font-style: italic;
  font-size: 1em;
  margin-top: 0.5em;
}

.totalAmountInfo {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.63);
  font-style: italic;
}

.confirmationDiv {
  width: 70%;
  margin: auto;
  margin-top: 0.5em;
  margin-bottom: 1em;
  font-weight: 500;
  font-style: italic;
}

.confirmationText {
  margin-bottom: 0rem;
}

.confirmationButton {
  border-radius: 7px;
  padding: 0.5em;
  margin-top: 0.5em;
}

.confirmationButton:hover {
  font-weight: 800;
  background-color: var(--primary-purple);
  color: white;
}

.wobble {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(2deg);
    transform: scale(0.8);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.height {
  display: none;
}

.displayNone {
  display: none;
}

@media (max-width: 600px) {
  .marginBlock {
    max-height: 8em;
    overflow-y: scroll;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin-block: 0;
    background-color: rgba(128, 128, 128, 0.207);
  }

  .displayNone {
    display: inline-block;
  }

  .boxShadow {
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: 600px) {
  .sentImage {
    width: 15em;
    height: 15em;
    object-fit: contain;
  }
}

@media (max-width: 600px) {
  .scheduleButtonOpen {
    width: 90%;
  }
  .scheduleButtonClosed {
    width: 90%;
  }
}
