.deliveryInfo {
  margin-bottom: 1em;
  position: relative;
  cursor: pointer;
}

.deliveryInfoDisabled {
  background-color: white;
  box-shadow: var(--box-shadow-2);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem;
  padding-top: 0rem;
}

.offerInfoDiv {
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.538);
  font-size: 0.9em;
}

.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 100%;
  height: 3rem;
  background-color: var(--primary-purple);
  font-family: "Editorial";
  color: white;

  border-radius: 3px;
}

.continueButton:hover {
  opacity: 0.8;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.checkMark {
  color: var(--primary-purple);
}

.mainText {
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  box-shadow: var(--box-shadow-2);
  border: 1px solid var(--primary-border);

  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
  transition: background-color 0.3s;
}

.mainText:hover {
  background-color: #f5f2ee;
  box-shadow: var(--box-shadow-2);
  cursor: pointer;
}

.mainText:active {
  background-color: var(--primary-bg-dark);
  box-shadow: var(--box-shadow-2);
}

.mainTextActive {
  background-color: white;
  /* box-shadow: var(--box-shadow-1); */

  /* border: 1px solid var(--primary-purple); */
  /* border-radius: 8px; */
  color: var(--primary-text);

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  /* padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem; */
  margin-bottom: 0rem;
}

.mainTextSummary {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0rem;
}

.timesInputsText {
  display: flex;
  padding-top: 0.75rem;
  border-top: 1px solid var(--primary-border);

  flex-direction: column;
  gap: 0.5rem;
}

.cartPage {
  max-width: 1452px;
  padding-left: 8.75rem;
  padding-right: 8.75rem;
  margin-left: auto;
  margin-right: auto;
}

.bg {
  background-color: var(--primary-bg);
  min-height: 100vh;
}

.sellerBatch {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.cartHeader {
  padding-top: 3.25rem;
  margin-bottom: 2rem;

  font-size: 32px;
  font-weight: 600;
}
.sellerBatches {
  width: 60%;
  display: inline-block;
}

.cartSummary {
  width: 35%;
  margin-left: 2.5%;
  display: inline-block;

  min-height: 10em;
  vertical-align: top;
}

.deliveryInput {
  background-color: white;
  min-height: 3em;
  margin-bottom: 1em;
  padding: 1rem;
}

.deliveryInputError {
  background-color: white;
  min-height: 3em;
  margin-bottom: 1em;
  padding: 1rem;
  border: 1px dashed var(--primary-red-400);
}

.cartOverview {
  padding: 1rem;
  background-color: white;
  margin-bottom: 1rem;
}

.listingPicture {
  max-height: 4em;
  max-width: 4em;
}

.listingTitle {
  color: black;
  font-weight: 500;

  font-size: 14px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.listingPrice {
  display: inline-block;
  /* width: 100%; */
  font-weight: 500;
  white-space: nowrap;
  margin-left: auto;
}

.listingPriceOg {
  display: inline-block;
  /* width: 100%; */
  font-weight: 400;
  text-decoration: line-through;
  color: var(--text-mute);
  margin-left: auto;
}

.listingPrice0 {
  display: inline-block;
  /* width: 100%; */
  font-weight: 300;
  text-decoration: line-through;
  margin-left: auto;
  margin-right: 1.5rem;
}

.rowGap1 {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: auto;
  gap: 0.25rem;
  justify-content: flex-end;
}

.listingPrice2 {
  display: inline-block;
  /* width: 100%; */
  font-weight: 500;
  margin-right: 0rem;
}

.listingPrice1 {
  display: inline-block;
  /* width: 100%; */
  font-weight: 500;
  margin-left: auto;
  margin-right: 1.5rem;
}

.contentPriceGroup {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.qty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  padding-left: 0.5rem;
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99a3a6;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  border: 0.5px solid #99a3a6;
  padding: 5px;
  cursor: pointer;
}

.totalQty {
  color: #99a3a6;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.quantityContainer {
  color: black;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1em;
}

.imgWrapper {
  position: relative;
  border: 1px solid var(--primary-bg);
  border-radius: 5px;
  display: inline;
}

.cartListingImg {
  position: relative;
  background-color: var(--primary-bg);
  /* border: 1px solid var(--primary-border); */
  object-fit: contain;
  height: 100px;
  width: 100px;
  border-radius: 5px;
}

.cartListingImgNonActive {
  position: relative;
  background-color: var(--primary-bg);

  object-fit: contain;
  height: 100px;
  width: 100px;
  border-radius: 5px;
  opacity: 0.4;
}

.prices {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.sold {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tetradic-blue);
  text-align: center;
  color: white;
  font-weight: 500;
  opacity: 0.8;
  width: 100%;
}

.remove {
  margin-top: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.639);
  text-decoration: underline;
  font-size: 12px;
}

.listingContainer {
  position: relative;

  display: flex;
}
.listingContainerLast {
  position: relative;
  display: flex;
}

.infoSec {
  width: 100%;
  padding-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
}

.goToCheckout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple);
  width: 100%;
  color: white;
  font-weight: 500;
  min-height: 3em;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.goToCheckoutDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple);
  width: 100%;
  color: white;
  font-weight: 500;
  min-height: 3em;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.goToCheckout:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.showBox {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.error {
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  width: fit-content;
  margin-bottom: 0rem;
  color: var(--primary-red-400);
}

.roundBorder {
  border: 1px solid var(--text-main);
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round {
  background-color: white;
  border-radius: 100%;
  width: 70%;
  height: 70%;
  transition: all 0.3s ease-in-out;
}

.roundActive {
  width: 65%;
  height: 65%;
  background-color: var(--text-main);
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
}

.deliveryOption {
  position: relative;

  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: var(--text-main);
}

.colSpace075 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.colSpace075actual {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.enterAddress {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1em 1rem;
}
.chooseOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-body);
  font-weight: 400;
  font-size: 16px;
  padding: 0em 0rem;
  position: relative;
  margin-bottom: 0rem;
}

.space {
  padding-top: 1rem;
}

.arrowForward {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.639);
}
.arrowForwardActive {
  transform: rotate(90deg);
}

.selectBox1 {
  margin-top: 0rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  background-color: white;
  border: 1px solid var(--primary-border);

  padding: 0.75rem 1rem;
  /* vertical-align: middle; */
  border-radius: 4px;
  transition: all 0.3s linear;
}

.selectBox1:hover {
  box-shadow: var(--box-shadow-2);
  background-color: var(--primary-bg);
}
.selectBox1:active {
  background-color: var(--primary-bg-dark);
  border: 1px solid var(--primary-purple);
}
.selectBoxError {
  overflow: hidden;

  margin-top: 0rem;

  background-color: white;
  border-radius: 4px;
  padding: 0.75rem 1rem;

  transition: 0.5s linear;

  border: 1px dashed var(--primary-red-400);
}

.selectBoxError:hover {
  box-shadow: var(--box-shadow-2);
  background-color: var(--primary-bg);
}

.selectBox {
  margin-top: 0rem;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--primary-border);
  border-radius: 4px;
  padding: 0.75rem 1rem;
  transition: 0.5s linear;
}

.selectBox:hover {
  box-shadow: var(--box-shadow-2);
  background-color: var(--primary-bg);
}

.storeHours {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  color: var(--text-body);

  transition: all 0.3s ease-in-out;
}

.storeHours:hover {
  color: var(--text-main);
}

.storeHours:focus {
  color: var(--text-mute);
}

.infoIcon {
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
}

.colSpace2 {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.col {
  display: flex;
  flex-direction: column;
}

.infoText {
  font-size: 14px;
  margin-bottom: 0rem;
}

.infoTextLarge {
  font-size: 14px;
  margin-bottom: 0rem;
  font-weight: 500;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.right {
  margin-left: auto;
  margin-right: 2rem;
  width: 1rem;
  height: 1rem;
}

.infoTextSmall {
  font-size: 13px;
  color: var(--text-body);
  font-weight: 400;
  margin-bottom: 0rem;
}
.infoTextSmall200 {
  font-size: 13px;
  color: var(--text-body);
  font-weight: 200;
  margin-bottom: 0rem;
}

.deliveryInfo {
  width: 100%;
  text-align: center;
  margin-bottom: 0rem;
}

.smallDeliveryInfo {
  font-weight: 200;
  color: var(--text-body);

  font-size: 14px;
}

.imgLocation {
  width: 1rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.deliveryContainer {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.subText {
  color: #9f9f9f;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--primary-bg);
}

.muteText {
  color: #9f9f9f;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 1.5;
}

.subTextMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summarySection {
  border-bottom: 1px solid var(--primary-bg);
}

.underLine {
  text-decoration: underline;
  color: #9f9f9f;
}

.underLine:hover {
  color: black;
}

.payments {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.payIconBox {
  margin-right: 1ch;
}
.payIcon {
  width: 4em;
  height: 2em;
  border: 0.5px solid #99a3a6;
  padding: 3px 3px;
  border-radius: 2px;
}

.placeHolderDiv {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
}

.placeholder1 {
  width: 60%;
  height: 90vh;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 50%,
    hsl(0, 0%, 100%) 100%
  );
  animation: anim 2s infinite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.placeholder2 {
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 50%,
    hsl(0, 0%, 100%) 100%
  );
  animation: anim 2s infinite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 20rem;
  margin-left: 3.5%;
  margin-bottom: 1rem;
}
.col1 {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 80vh;
}

@keyframes anim {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.placeholder3 {
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 50%,
    hsl(0, 0%, 100%) 100%
  );
  animation: anim 2s infinite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 10rem;
  margin-left: 3.5%;
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .contentPriceGroup {
    flex-direction: column;
    gap: 0;
  }
  .cartHeader {
    text-align: center;
    font-size: 28px;
    margin-bottom: 3.25rem;
  }

  .infoText {
    line-height: 150%;
  }

  .mainTextActive {
    padding: 1rem;
    box-shadow: var(--box-shadow-2);
    border-radius: 5px;
    border: 1px solid var(--primary-border);
  }

  .sellerBatch {
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 5px;
    border: none;
    background-color: white;
  }

  .deliveryInfoDisabled {
    padding: 0;
    row-gap: 1.5rem;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .placeholder1 {
    width: 100%;
  }
  .col1 {
    width: 100%;
  }

  .deliveryContainer {
    font-size: 14px;
  }

  .subText {
    font-size: 14px;
  }

  .subTextMain {
    font-size: 14px;
  }

  .mainTextSummary {
    font-size: 14px;
  }
  .listingTitle {
    font-size: 14px;
    font-weight: 400;
  }

  .listingPrice {
    font-size: 14px;
  }

  .listingPriceOg {
    font-size: 14px;
  }

  .listingPrice1 {
    font-size: 14px;
  }

  .deliveryOption {
    font-size: 14px;
  }

  .muteText {
    font-size: 13px;
  }

  .cartPage {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .sellerBatches {
    width: 100%;
  }

  .infoTextSmall {
    font-size: 13px;
    line-height: 150%;
  }

  .infoTextSmall200 {
    font-size: 13px;
    line-height: 150%;
  }
  .cartSummary {
    width: 100%;
    margin-left: 0rem;
  }
}
