.filters {
  width: 100%;
}

.chatsContainer {
  height: calc(100% - 60px - 2rem);

  overflow-y: scroll;
  overflow-x: auto;
}

.chatsContainerSidebar {
  overflow-y: scroll;
  overflow-x: auto;
  height: 92%;
}

.navButtons {
  display: flex;
  height: 60px;
  z-index: 100;
}

.idle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  background-color: white;
  position: relative;
  border-bottom: 1px solid var(--primary-bg);
  cursor: pointer;
}

.selected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  color: var(--primary-purple);
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid var(--primary-purple);
  cursor: pointer;
}

.idle:hover {
  color: var(--primary-purple);
  font-weight: 600;
}

.justifyBetween {
  position: relative;
  padding: 0rem 0.6rem;
  border-bottom: 1px solid var(--primary-bg);

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  height: 2rem;
}

.dropdown {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;

  z-index: 3;
  background-color: white;
  border-radius: 8px;
  border-bottom: 1px solid #dadada;

  padding: 0.5rem 0.75rem;
}
.selectionPill {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  border-bottom: 1px solid var(--primary-bg);
}
.listingSelectionPill {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #5e5e5e;
  color: white;
  font-size: 10px;
  border-radius: 3px;
  padding: 2px 0.5rem;
}
.deleteListingFilter {
  height: 1em;
  width: 1em;
  margin-right: 0.5rem;
  cursor: pointer;
}
.icon {
  transition: linear 0.2s;
}

.flip {
  transition: linear 0.2s;

  transform: rotate(180deg);
}

.selection {
  font-weight: 300;
  padding: 0.5em 1em;
  font-size: 14px;
  position: relative;
}

.littleChatNot {
  background-color: var(--primary-blue-400);
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}

.selection:hover {
  background-color: var(--primary-bg);
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

.noChats {
  margin-top: 1em;
  text-align: center;
}
