.inputDivInvisible {
  display: none;
}

.imageUpload:hover {
  cursor: pointer;
}

.imageDiv {
  max-width: fit-content;
}
.previewWindow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 450px;
}

.previewImage {
  border: 1px dashed var(--text-mute);
  background-color: var(--primary-bg);
  padding: 0.5rem;
  max-width: 100%;
  max-height: 400px;
  display: block;
  border-radius: 2px;
  margin: 0 auto 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.sendButton {
  background-color: var(--primary-purple);
  color: white;
  border-radius: 4px;
  width: auto;
  height: 2.5em;
  padding-left: 0.7em;
  padding-right: 0.7rem;
  margin-left: 0.2em;
  margin-right: 10px;
}

.sendButton:hover {
  opacity: 0.8;
}
