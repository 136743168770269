/* || (userChats.loading && chats.length === 0) */

.topSection {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid var(--primary-bg);
  padding: 1rem;
}

.singleTextPlaceholder {
  width: 40%;
  height: 2rem;
  border-radius: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(0, 1%, 70%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
}

.bottomSection {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.chatPreviewPlaceholder {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid var(--primary-bg);
  padding-left: 1rem;
  padding-right: 1rem;
}

.imagePlaceholder {
  max-height: 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 8px;
  width: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(0, 1%, 70%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
}

.flexCol {
  padding-left: 1rem;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.smallTextPlaceholder {
  width: 100%;
  margin-bottom: 4px;
  height: 10px;
  border-radius: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(0, 1%, 70%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
}

.smallTextPlaceholder2 {
  width: 30%;
  margin-bottom: 4px;
  height: 10px;
  border-radius: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(0, 1%, 70%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
}

@keyframes anim {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
