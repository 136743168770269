.main {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leftHeader {
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
}

.rightHeader {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.subHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}
.subFooter {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: end;
}
.footerButton {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: black;
  color: white;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  font-family: "Editorial";
  width: fit-content;
}
.pickerButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: white;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  font-family: "Editorial";
}

.arrowBack {
  color: var(--text-main);
  padding: 5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.arrowBack:hover {
  opacity: 0.8;
  background-color: var(--primary-bg-dark);
  border-radius: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
}

.button {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: white;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.datePickerInner {
  width: fit-content;
}

.button:hover {
  filter: brightness(0.95);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.2s ease-in-out;
}

.datePickerButtons {
  position: absolute;
  top: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  right: -111px;
  border: 1px solid var(--primary-border);
  width: 110px;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  background-color: white;
}

.dateButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  background-color: white;
  border-radius: 5px;
  padding: 2px 5px;
  font-family: "Editorial";
  border: 1px solid white;

  transition: 0.2s ease-in-out;
}

.dateButton:hover {
  filter: brightness(0.95);
  border: 1px solid var(--primary-border);
  background-color: var(--primary-bg);
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  min-height: 440px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: auto;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

/* GRAPH */

.graphContainer {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  /* width: 50%; */
  min-height: 440px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  /* row-gap: 25px; */
  min-height: 440px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.graphHeader {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}

.topTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.graphTitle {
  font-size: 12px;
  color: var(--text-body);
  font-weight: 300;
  margin-bottom: 0rem;
}

.buttonIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: var(--text-mute);
}

.buttonIcon:hover {
  color: var(--text-body);
}

.graphSubTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.sum {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.comparePercentPositive {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  font-size: 12px;
  color: var(--primary-green-400);
  font-weight: 500;
}
.comparePercentNegative {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;

  font-size: 12px;
  color: var(--primary-red-400);
  font-weight: 500;
}
.graph {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.dateRangeBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 0.5rem;
}

.dateRange {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  background-color: var(--primary-bg);
  border-radius: 3px;
  padding: 10px 10px;
}

.textCompare {
  font-size: 12px;
  color: var(--text-body);
  margin-bottom: 0rem;
}

.firstDateRangeColor {
  background-color: var(--primary-green-400);
  height: 2px;
  border-radius: 3px;
  width: 15px;
}

.compareDateRangeColor {
  background-color: var(--primary-red-400);
  height: 2px;
  border-radius: 3px;
  width: 15px;
}
.plainData {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  border-bottom: 1px solid var(--primary-border);
  font-size: 12px;
  color: var(--text-main);
  padding-bottom: 5px;
}
.plainDataRow {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--text-body);
  justify-content: space-between;
}

.rowJustifyBetween {
  display: flex;
  align-items: center;

  justify-content: space-between;
  border-bottom: 1px solid var(--primary-border);
  padding-bottom: 5px;
}

.colSpace2 {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.rowHeader {
  font-size: 12px;
  margin-bottom: 0rem;
  color: var(--text-main);
}

.popupContainer {
  position: absolute;
  top: 50px;
  left: 0;
  background-color: white;
  z-index: 999; /* Set the z-index to make sure it appears above other content */
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  max-width: 100%;
}

.contentPlaceholder {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  min-height: 440px;
  background-color: transparent;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--primary-border);

  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  width: auto;
}

.rowPlaceholder {
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.boxPlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.rowPlaceholderSmall {
  width: 50%;
  height: 20px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  background-color: var(--primary-bg);
  border-radius: 3px;
  padding: 10px 10px;
  width: 100%;
  border: 1px solid var(--primary-border);
  justify-content: space-between;
  position: relative;
}

.mobile {
  display: none;
}

@media (max-width: 600px) {
  .mobile {
    display: block;
  }
  .subHeader {
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .button {
    height: 48px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .leftHeader {
    margin-left: 1.5rem;
  }

  .content {
    min-height: 0px;
  }
}

@keyframes anim {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}
