.main {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-bg);
  min-height: 100vh;
  padding-bottom: 5rem;
}

.topNavBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  height: 75px;
}

.navBarContainer {
  max-width: var(--max-width);
  padding: 0px 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3rem;
}

.logo {
  height: 25px;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}

.formLabel {
  font-size: 13px;
  color: var(--text-body);
  margin-bottom: 0rem;
}

.optionalInfo {
  background-color: var(--primary-red-100);
  padding: 1rem;
  border-radius: 5px;
  position: relative;
}

.infoIcon {
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: var(--primary-red-400);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  color: white;
}

.optText {
  font-size: 12px;
  color: var(--text-main);
  margin-bottom: 0rem;
  line-height: 150%;
}

.rowFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
}

.rowSpace1 {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}
.rowSpace05 {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.stepText {
  font-size: 12px;
  font-family: "Editorial";
  color: var(--text-main);
}

.stepTextNonActive {
  font-size: 12px;
  font-family: "Editorial";
  color: var(--text-mute);
}

.progressBar {
  overflow: hidden;
  width: 100%;
  height: 10px;
  background-color: var(--primary-bg-dark);
  border-radius: 5px;
  border: 1px solid var(--primary-border);
}

.progressBarFill {
  height: 100%;
  background-color: var(--text-main);
  border-radius: 5px;
}

/* sections */

.mainContent {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  max-width: var(--max-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 1rem;
}

.colSpace1 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.cosignRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.consignImg {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid var(--primary-border);
}

.removeItem {
  font-size: 13px;
  color: var(--primary-red-500);
  margin-bottom: 0rem;
  display: flex;
  column-gap: 0.5rem;
  flex-direction: row;
  cursor: pointer;
}

.col {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.removeItem:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.arrowBack {
  color: var(--text-main);
  padding: 5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.arrowBack:hover {
  opacity: 0.8;
  background-color: var(--primary-bg-dark);
  border-radius: 5px;
}

.section {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.leftSection {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 30%;
}

.rightSection {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 70%;
}

.boxContainer {
  display: flex;
  padding: 15px;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.formTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0rem;
  display: flex;
  column-gap: 0.5rem;
  flex-direction: row;
}

.box {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
}

.howItWorksStep {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 100%;
  border: 1px solid var(--primary-border);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Editorial";
  font-size: 14px;
}

.howItWorksText {
  font-size: 12px;
  font-family: "Editorial";
  color: var(--text-body);
  margin-bottom: 0rem;
}

/* Action s */

.buttonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.2rem;
}

.button {
  display: flex;
  position: relative;
  flex-direction: row;
  text-transform: capitalize;

  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: white;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 10px 15px;
  height: fit-content;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}
.button:hover {
  filter: brightness(0.95);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.2s ease-in-out;
}

.buttonDark {
  display: flex;
  position: relative;
  height: fit-content;
  text-transform: capitalize;

  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--primary-purple);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  color: white;
  padding: 10px 15px;
  font-family: "Editorial";
  width: fit-content;
  transition: 0.2s ease-in-out;
}
.buttonDark:hover {
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.2s ease-in-out;
}

.images {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  /* grid-template-rows: repeat(2, 1fr);  */
  gap: 0.75rem;
}

.firstImage {
  position: relative;
  grid-column: span 2;
  grid-row: span 2;
  width: 100%;
  height: 100%;
  max-width: 100%; /* Limit the width to 100% of the container */
  max-height: 100%; /* Limit the height to 100% of the container */
  object-fit: contain;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.left {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 50%;
}

.right {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  width: 50%;
}

.minusBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-main);
  width: 15px;
  height: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.minusBox:hover {
  background-color: var(--text-body);
}

.minus {
  background-color: white;
  width: 50%;
  height: 2px;
}

.checkBoxAbsolute {
  position: absolute;
  display: flex;
  top: 0.75rem;
  left: 0.75rem;
  width: 16px;
  accent-color: var(--text-main);
  height: 16px;
}

.image {
  position: relative;

  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 1/1;
}
.image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1/1;

  overflow: hidden;
}
.addImage {
  grid-column: span 1;
  aspect-ratio: 1/1;

  grid-row: span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--primary-border);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.addImgButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: white;
  border: 1px solid var(--primary-border);
  background-color: var(--primary-bg-dark);

  border-radius: 5px;
  padding: 7px 10px;
  height: fit-content;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.addImgButton:hover {
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.2s ease-in-out;
}

.inputDivInvisible {
  display: none;
}

.contentGroup {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}

.contentGroupGrey {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;

  background-color: var(--primary-bg);
  border-radius: 5px;
  padding: 15px;
}

.link {
  font-size: 13px;
  color: var(--primary-purple);
  margin-bottom: 0rem;
  display: flex;
  column-gap: 0.5rem;
  flex-direction: row;
  cursor: pointer;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  background-color: white;
  border: 1px dashed var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 13px;
  height: fit-content;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.input:focus {
  outline: none;
  border: 1px solid var(--text-main);
}

.inputTextarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  background-color: white;
  border: 1px dashed var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 13px;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.inputTextarea:focus {
  outline: none;
  border: 1px solid var(--text-main);
}

.selectBox {
  padding: 10px 10px;
  background-color: var(--primary-bg);
  font-size: 13px;
  cursor: pointer;

  border: 1px solid var(--primary-border);
  border-radius: 3px;
}

.selectBoxActive {
  padding: 10px 10px;
  background-color: var(--text-main);
  color: white;
  border: 1px solid var(--primary-border);
  border-radius: 3px;

  font-size: 13px;
  cursor: pointer;
}

.selectBoxError {
  padding: 10px 10px;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;

  color: var(--primary-red-500);
  font-size: 13px;
  border: 1px dashed var(--primary-red-500);
}

/* hover */
.selectBox:hover {
  opacity: 0.8;
}

.selectBoxActive:hover {
  opacity: 0.8;
}

.selectBoxError:hover {
  opacity: 0.8;
}

@media (max-width: 600px) {
  .navBarContainer {
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 1rem;
    align-items: flex-start;
  }

  .topNavBar {
    height: auto;
    align-items: flex-start;
  }

  .logo {
    height: 20px;
    margin-right: auto;
  }

  .stepsContainer {
    width: 100%;
  }

  .section {
    flex-direction: column;
    row-gap: 1rem;
  }

  .leftSection {
    width: 100%;
  }

  .rightSection {
    width: 100%;
  }

  .rowSpace1 {
    flex-direction: column;
    row-gap: 1rem;
  }

  .right {
    width: 100%;
  }

  .left {
    width: 100%;
  }
}
